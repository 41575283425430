/* unplugin-vue-components disabled */import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "i-frame"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.ready ? (_openBlock(), _createElementBlock("iframe", {
    key: 0,
    src: $props.src,
    frameborder: "0",
    class: "i-frame-iframe"
  }, null, 8 /* PROPS */, _hoisted_2)) : _createCommentVNode("v-if", true)]);
}