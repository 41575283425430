/* unplugin-vue-components disabled */import { nextTick } from 'vue';
import { mapState, mapGetters } from 'vuex';
import Setting from '@/setting';
import iMenuSideItem from './menu-item';
import iMenuSideSubmenu from './submenu';
import iMenuSideCollapse from './menu-collapse';
import tTitle from '../mixins/translate-title';

// 元素是否在可视区域
function isElementInViewport(el) {
  let rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}
export default {
  name: 'iMenuSide',
  mixins: [tTitle],
  components: {
    iMenuSideItem,
    iMenuSideSubmenu,
    iMenuSideCollapse
  },
  props: {
    hideLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('admin/layout', ['siderTheme', 'menuAccordion', 'menuCollapse']),
    ...mapState('admin/menu', ['activePath', 'openNames']),
    ...mapGetters('admin/menu', ['filterSider'])
  },
  watch: {
    '$route': {
      handler() {
        this.handleUpdateMenuState();
      },
      immediate: true
    },
    // 在展开/收起侧边菜单栏时，更新一次 menu 的状态
    menuCollapse() {
      this.handleUpdateMenuState();
    }
  },
  methods: {
    handleUpdateMenuState() {
      nextTick(() => {
        if (this.$refs.menu) {
          this.$refs.menu.updateActiveName();
          if (this.menuAccordion) this.$refs.menu.updateOpened();
          // 聚焦当前项
          nextTick(() => {
            const $activeMenu = document.getElementsByClassName('ivu-menu-item ivu-menu-item-active ivu-menu-item-selected');
            if ($activeMenu && $activeMenu.length && !isElementInViewport($activeMenu[0])) {
              const activeMenuTop = $activeMenu[0].getBoundingClientRect().top - Setting.headerHeight;
              const $menu = this.$refs.menu.$el;
              setTimeout(() => {
                this.$ScrollTop($menu, {
                  to: activeMenuTop,
                  time: 0
                });
              }, 300);
            }
          });
        }
      });
    }
  }
};