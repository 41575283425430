/* unplugin-vue-components disabled *//**
 * 该组件除了 Menu，也被 Breadcrumb 使用过
 * */
import { mapState } from 'vuex';
import tTitle from '../mixins/translate-title';
export default {
  name: 'iMenuHeadTitle',
  mixins: [tTitle],
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    badge: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('admin/menu', ['headerMenuBadge']),
    badgeData() {
      let data = null;
      const headerMenuBadge = this.headerMenuBadge.find(item => item.path === this.item.path);
      if (headerMenuBadge) data = headerMenuBadge;
      return data;
    }
  }
};