/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  return _openBlock(), _createElementBlock("span", {
    class: "i-layout-header-trigger i-layout-header-trigger-min",
    onClick: _cache[0] || (_cache[0] = (...args) => _ctx.toggleFullscreen && _ctx.toggleFullscreen(...args))
  }, [_withDirectives(_createVNode(_component_Icon, {
    custom: "i-icon i-icon-full-screen"
  }, null, 512 /* NEED_PATCH */), [[_vShow, !_ctx.isFullscreen]]), _withDirectives(_createVNode(_component_Icon, {
    custom: "i-icon i-icon-exit-full-screen"
  }, null, 512 /* NEED_PATCH */), [[_vShow, _ctx.isFullscreen]])]);
}