// 菜单，侧边栏
import marketing from './modules/marketing';
import customOperate from './modules/custom-operate';
import customGroup from './modules/custom-group';
import sysConfig from './modules/sys-config';
import salesManagement from './modules/sales-management';

// 系统
import log from './modules/log';
export default [
// {
//     path: '/test',
//     title: '基础',
//     header: 'home',
// },
marketing, customOperate, customGroup, sysConfig, salesManagement, log];