const pre = '/sales-management/';
export default {
  path: '/sales-management',
  title: '销售管理',
  header: 'home',
  icon: 'ios-settings',
  img: 'https://qiniu.hp.gimyingao.com/wecom/%E9%85%8D%E7%BD%AE2.png',
  imgS: 'https://qiniu.hp.gimyingao.com/wecom/%E9%85%8D%E7%BD%AE.png',
  children: [{
    path: `${pre}clue-highSeas`,
    title: '线索公海'
  }, {
    path: `${pre}high-seas`,
    title: '公海管理',
    hide: true
  }, {
    path: `${pre}clue`,
    title: '线索管理',
    hide: true
  }, {
    path: `${pre}clue-custom`,
    title: '线索客户',
    hide: true
  }, {
    path: `${pre}clue-statistics`,
    title: '线索统计',
    hide: true
  }, {
    path: `${pre}enterprise`,
    title: '企业客户',
    hide: true
  }, {
    path: `${pre}high-seas/create`,
    title: '公海-创建',
    hide: true
  }, {
    path: `${pre}custom-sop`,
    title: '客户SOP'
  }, {
    path: `${pre}custom-sop/create`,
    title: '创建客户SOP',
    hide: true
  }, {
    path: `${pre}custom-sop/data`,
    title: '执行数据',
    hide: true
  }, {
    path: `${pre}business-management`,
    title: '商机管理'
  }, {
    path: `${pre}business-management/create`,
    title: '创建商机',
    hide: true
  }, {
    path: `${pre}order-management`,
    title: '订单管理'
  }, {
    path: `${pre}order-management/create`,
    title: '新建订单',
    hide: true
  }, {
    path: `${pre}order-management/setting`,
    title: '订单设置',
    hide: true
  }, {
    path: `${pre}qualified-clues`,
    title: '线索合格定义'
  }]
};