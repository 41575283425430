const pre = '/sys-config/';
export default {
  path: '/sys-config',
  title: '基础管理',
  header: 'home',
  icon: 'ios-settings',
  img: 'https://qiniu.hp.gimyingao.com/wecom/%E9%85%8D%E7%BD%AE2.png',
  imgS: 'https://qiniu.hp.gimyingao.com/wecom/%E9%85%8D%E7%BD%AE.png',
  children: [{
    path: `${pre}member-list`,
    title: '员工管理'
  }, {
    path: `${pre}permission-manage`,
    title: '权限管理'
  }, {
    path: `${pre}sensitive`,
    title: '敏感消息管理'
  }, {
    path: `${pre}speechcraft`,
    title: '话术库'
  }]
};