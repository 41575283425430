import request from '@/plugins/request';
export function getUser() {
  return request({
    url: '/api/admin/get_me',
    method: 'get'
  });
}
/**
 * 获取企微登录二维码跳转链接
 * @returns 
 */
export const getLoginUrl = () => request({
  url: '/api/admin/generate_login_link',
  method: 'get'
});
/**
 * 登录
 * @param {*} data 
 * @returns 
 */
export const loginFn = params => request({
  url: '/api/admin/login',
  method: 'get',
  params
});

/**
 * 
 * 退出
 */
export function logoutAction() {
  return request({
    url: '/api/admin/logout',
    method: 'post'
  });
}