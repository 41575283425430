import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
const pre = 'custom-group-';
export default {
  path: '/custom-group',
  name: 'custom-group',
  redirect: {
    name: `${pre}group-list`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: 'group-list',
    name: `${pre}group-list`,
    meta: {
      ...meta,
      title: '客户群列表'
    },
    component: () => import('@/pages/custom-group/group-list')
  }, {
    path: 'group-label',
    name: `${pre}group-label`,
    meta: {
      ...meta,
      title: '客户群标签'
    },
    component: () => import('@/pages/custom-group/group-label')
  }, {
    path: 'crowd-send',
    name: `${pre}crowd-send`,
    meta: {
      ...meta,
      title: '客户群群发'
    },
    component: () => import('@/pages/custom-group/crowd-send')
  }, {
    path: 'group-sop',
    name: `${pre}group-sop`,
    meta: {
      ...meta,
      title: '群SOP'
    },
    component: () => import('@/pages/custom-group/group-sop')
  }, {
    path: 'group-sop/create',
    name: `${pre}group-sop-create`,
    meta: {
      ...meta,
      title: '创建群SOP任务'
    },
    component: () => import('@/pages/custom-group/group-sop/create.vue')
  }, {
    path: 'group-sop/info',
    name: `${pre}group-sop-info`,
    meta: {
      ...meta,
      title: '任务详情'
    },
    component: () => import('@/pages/custom-group/group-sop/info.vue')
  }, {
    path: 'crowd-send/info',
    name: `${pre}crowd-send-info`,
    meta: {
      ...meta,
      title: '群发详情'
    },
    component: () => import('@/pages/custom-group/crowd-send/components/info.vue')
  }, {
    path: 'crowd-send/create',
    name: `${pre}crowd-send-create`,
    meta: {
      ...meta,
      title: '新建群发'
    },
    component: () => import('@/pages/custom-group/crowd-send/components/create.vue')
  }, {
    path: 'group-statistics',
    name: `${pre}group-statistics`,
    meta: {
      ...meta,
      title: '客户群统计'
    },
    component: () => import('@/pages/custom-group/group-statistics')
  }, {
    path: "auto-pull",
    name: `${pre}channel-auto-pull`,
    meta: {
      ...meta,
      title: "自动拉群"
    },
    component: () => import("@/pages/custom-group/auto-pull")
  }, {
    path: "auto-pull/create",
    name: `${pre}channel-auto-pull-create`,
    meta: {
      ...meta,
      title: "新建拉群"
    },
    component: () => import("@/pages/custom-group/auto-pull/create.vue")
  }, {
    path: "auto-pull/edit",
    name: `${pre}channel-auto-pull-edit`,
    meta: {
      ...meta,
      title: "编辑拉群"
    },
    component: () => import("@/pages/custom-group/auto-pull/create.vue")
  }, {
    path: "label-group",
    name: `${pre}label-group`,
    meta: {
      ...meta,
      title: "标签建群"
    },
    component: () => import("@/pages/custom-group/label-group/index.vue")
  }, {
    path: "label-group/info",
    name: `${pre}label-group/info`,
    meta: {
      ...meta,
      title: "详情"
    },
    component: () => import("@/pages/custom-group/label-group/components/info.vue")
  }, {
    path: "label-group/create",
    name: `${pre}label-group/create`,
    meta: {
      ...meta,
      title: "创建群邀请"
    },
    component: () => import("@/pages/custom-group/label-group/components/create.vue")
  }]
};