/* unplugin-vue-components disabled */import iMenuSideItem from './menu-item';
import iMenuSideTitle from './menu-title';
import siderMenuBadge from '../mixins/sider-menu-badge';
export default {
  name: 'iMenuSideSubmenu',
  components: {
    iMenuSideItem,
    iMenuSideTitle
  },
  mixins: [siderMenuBadge],
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    },
    index: {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  data() {
    return {
      tit: ''
    };
  },
  methods: {
    menuM(item, type) {
      // console.log(item,type)
      if (type === 1) {
        this.tit = item.title;
      } else if (type === 2) {
        this.tit = '';
      }
    }
  }
};