/* unplugin-vue-components disabled */import { mapState, mapActions } from 'vuex';
export default {
  name: 'iHeaderUser',
  computed: {
    ...mapState('admin/user', ['info', 'defaultAvatar']),
    ...mapState('admin/layout', ['isMobile', 'logoutConfirm'])
  },
  methods: {
    ...mapActions('admin/account', ['logout']),
    handleClick(name) {
      if (name === 'logout') {
        this.logout({
          confirm: this.logoutConfirm,
          vm: this
        });
      }
    }
  }
};