/* unplugin-vue-components disabled */import tTitle from '../mixins/translate-title';
export default {
  name: 'iMenuSideTitle',
  mixins: [tTitle],
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    // 用于侧边栏收起 Dropdown 当前高亮
    selected: {
      type: Boolean,
      default: false
    },
    // 侧边栏折叠状态
    collapse: {
      type: Boolean,
      default: false
    },
    tit: {
      type: String,
      default() {
        return '';
      }
    }
  },
  computed: {
    withIcon() {
      return this.menu.icon || this.menu.custom || this.menu.img;
    }
  },
  watch: {
    tit() {
      this.title = this.tit;
    }
  },
  data() {
    return {
      title: this.tit,
      selectTit: ''
    };
  },
  methods: {
    selectMenu(item) {
      // // console.log(JSON.parse(sessionStorage.getItem('selectMeun')))
      // // console.log(this.menu)
      // const selectMeun = sessionStorage.getItem('selectMeun')
      // // console.log(selectMeun.indexOf(item.path))
      // if(selectMeun.indexOf(item.path)!=-1){
      //     this.selectTit = JSON.parse(selectMeun).title
      //     // console.log(this.selectTit)
      // }
    }
  }
};