/* unplugin-vue-components disabled */import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  src: "https://wecom-gim-bcs.gimyingao.com/d7142bda457911eeadc20685102b3da2.png"
};
const _hoisted_2 = {
  key: 1,
  src: "http://siemens-wecom-test.gimyingao.com/dev/material/dev_20230922162711qWcdyn3W.png"
};
const _hoisted_3 = {
  key: 2,
  src: "http://siemens-wecom-test.gimyingao.com/dev/material/dev_20230922161722FH4DVyf8.png"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_link = _resolveComponent("i-link");
  const _component_i_menu_side_item = _resolveComponent("i-menu-side-item");
  const _component_i_menu_side_submenu = _resolveComponent("i-menu-side-submenu");
  const _component_Tooltip = _resolveComponent("Tooltip");
  const _component_i_menu_side_collapse = _resolveComponent("i-menu-side-collapse");
  const _component_Menu = _resolveComponent("Menu");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass(["i-layout-sider-logo", {
      'i-layout-sider-logo-dark': _ctx.siderTheme === 'dark'
    }])
  }, [_createVNode(_Transition, {
    name: "fade-quick",
    persisted: ""
  }, {
    default: _withCtx(() => [_withDirectives(_createVNode(_component_i_link, {
      to: "/"
    }, {
      default: _withCtx(() => [_ctx.menuCollapse ? (_openBlock(), _createElementBlock("img", _hoisted_1)) : _ctx.siderTheme === 'light' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3))]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [[_vShow, !$props.hideLogo]])]),
    _: 1 /* STABLE */
  })], 2 /* CLASS */), _createVNode(_component_Menu, {
    ref: "menu",
    class: _normalizeClass(["i-layout-menu-side i-scrollbar-hide", {
      'i-layout-menu-side-collapse': _ctx.menuCollapse
    }]),
    theme: _ctx.siderTheme,
    accordion: _ctx.menuAccordion,
    "active-name": _ctx.activePath,
    "open-names": _ctx.openNames,
    width: "auto"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterSider, (item, index) => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: index
      }, [!_ctx.menuCollapse ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [item.children === undefined || !item.children.length ? (_openBlock(), _createBlock(_component_i_menu_side_item, {
        key: 0,
        menu: item
      }, null, 8 /* PROPS */, ["menu"])) : (_openBlock(), _createBlock(_component_i_menu_side_submenu, {
        key: 1,
        menu: item
      }, null, 8 /* PROPS */, ["menu"]))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [item.children === undefined || !item.children.length ? (_openBlock(), _createBlock(_component_Tooltip, {
        content: _ctx.tTitle(item.title),
        placement: "right",
        key: index,
        theme: _ctx.siderTheme
      }, {
        default: _withCtx(() => [_createVNode(_component_i_menu_side_item, {
          class: "i-layout-menu-side-collapse-top-item",
          menu: item,
          "hide-title": ""
        }, null, 8 /* PROPS */, ["menu"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content", "theme"])) : (_openBlock(), _createBlock(_component_i_menu_side_collapse, {
        key: 1,
        menu: item,
        "top-level": ""
      }, null, 8 /* PROPS */, ["menu"]))], 64 /* STABLE_FRAGMENT */))], 64 /* STABLE_FRAGMENT */);
    }), 128 /* KEYED_FRAGMENT */))]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "theme", "accordion", "active-name", "open-names"])]);
}