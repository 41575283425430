const pre = "/marketing/";
export default {
  path: "/marketing",
  title: "营销获客",
  header: "home",
  icon: "md-speedometer",
  img: "https://qiniu.hp.gimyingao.com/wecom/%E8%90%A5%E9%94%802.png",
  imgS: "https://qiniu.hp.gimyingao.com/wecom/%E8%90%A5%E9%94%80.png",
  children: [{
    path: `${pre}channel-qr`,
    title: "渠道活码"
  }, {
    path: `${pre}store-qr`,
    title: "地区活码"
  }, {
    path: `${pre}auto-pull`,
    title: "自动拉群"
  }, {
    path: `${pre}customer-fission`,
    title: "客户裂变"
  }, {
    path: `${pre}group-fission`,
    title: "群裂变"
  }, {
    path: `${pre}channel-qr/create`,
    title: "渠道活码-创建",
    hide: true
  }, {
    path: `${pre}channel-qr/update`,
    title: "渠道活码-修改",
    hide: true
  }, {
    path: `${pre}channel-qr/copy`,
    title: "渠道活码-复制",
    hide: true
  }, {
    path: `${pre}channel-qr/count`,
    title: "渠道活码-数据统计",
    hide: true
  }, {
    path: `${pre}auto-pull/create`,
    title: "新建拉群",
    hide: true
  }, {
    path: `${pre}auto-pull/edit`,
    title: "编辑拉群",
    hide: true
  }, {
    path: `${pre}customer-fission/create`,
    title: "客户裂变-创建活动",
    hide: true
  }, {
    path: `${pre}customer-fission/count`,
    title: "客户裂变-数据统计",
    hide: true
  }, {
    path: `${pre}group-fission/create`,
    title: "群裂变-创建活动",
    hide: true
  }, {
    path: `${pre}group-fission/count`,
    title: "群裂变-数据统计",
    hide: true
  }, {
    path: `${pre}group-fission/invited`,
    title: "群裂变-邀请客户参与",
    hide: true
  }, {
    path: `${pre}add-friends`,
    title: "批量加好友"
  }, {
    path: `${pre}add-friends/info`,
    title: "导入记录",
    hide: true
  }, {
    path: `${pre}label-group`,
    title: "标签建群"
  }, {
    path: `${pre}label-group/info`,
    title: "详情",
    hide: true
  }, {
    path: `${pre}label-group/create`,
    title: "创建群邀请",
    hide: true
  }, {
    path: `${pre}questionnaire-form`,
    title: "问卷表单"
  }, {
    path: `${pre}questionnaire-form/create`,
    title: "新建表单",
    hide: true
  }, {
    path: `${pre}questionnaire-form/info`,
    title: "数据详情",
    hide: true
  }]
};