/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_side_title = _resolveComponent("i-menu-side-title");
  const _component_Badge = _resolveComponent("Badge");
  const _component_MenuItem = _resolveComponent("MenuItem", true);
  return !$props.menu.hide ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_MenuItem, _mergeProps(_ctx.$attrs, {
    to: $props.menu.path,
    replace: $props.menu.replace,
    target: $props.menu.target,
    name: $props.menu.path,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.handleClick($props.menu.path))
  }), {
    default: _withCtx(() => [_createVNode(_component_i_menu_side_title, {
      menu: $props.menu,
      "hide-title": $props.hideTitle
    }, null, 8 /* PROPS */, ["menu", "hide-title"]), _ctx.badgeData ? (_openBlock(), _createBlock(_component_Badge, _mergeProps({
      key: 0,
      class: "i-layout-menu-side-badge"
    }, _ctx.badgeData), null, 16 /* FULL_PROPS */)) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["to", "replace", "target", "name"])])) : _createCommentVNode("v-if", true);
}