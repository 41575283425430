import { h } from 'vue';
import marketing from './modules/marketing';
import customOperate from './modules/custom-operate'; //客户营销

import customManage from './modules/custom-manage'; //客户管理

import customGroup from './modules/custom-group'; //客户群运营

import sysConfig from './modules/sys-config';
import newCustomer from './modules/new-customer';
import salesManagement from './modules/sales-management';
import wechatService from './modules/wechat-service';
import BasicLayout from '@/layouts/basic-layout';

/**
 * 在主框架内显示
 */

const frameIn = [{
  path: '/',
  redirect: {
    name: 'custom-manage-customer-management'
  },
  component: BasicLayout,
  children: [{
    path: 'index',
    name: 'index',
    redirect: {
      name: 'custom-manage-customer-management'
    }
  }, {
    path: 'log',
    name: 'system-log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: () => import('@/pages/system/log')
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: {
      beforeRouteEnter(to, from, next) {
        next(instance => instance.$router.replace(from.fullPath));
      },
      render: () => h()
    }
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: {
      beforeRouteEnter(to, from, next) {
        next(instance => instance.$router.replace(JSON.parse(from.params.route)));
      },
      render: () => h()
    }
  }, {
    path: 'test',
    name: 'test',
    meta: {
      title: '基础'
    },
    component: () => import('@/pages/test')
  }]
}, {
  path: '/form_editor',
  name: 'form_editor',
  meta: {
    title: '新建表单'
  },
  component: () => import('@/pages/marketing/questionnaire-form/create.vue')
}, marketing, customManage, customOperate, customGroup, newCustomer, sysConfig, wechatService, salesManagement];

/**
 * 在主框架之外显示
 */

const frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  meta: {
    title: '$t:page.login.title'
  },
  component: () => import('@/pages/account/login')
},
// 注册
{
  path: '/register',
  name: 'register',
  meta: {
    title: '$t:page.register.title'
  },
  component: () => import('@/pages/account/register')
},
// 注册结果
{
  path: '/register/result',
  name: 'register-result',
  meta: {
    auth: true,
    title: '注册结果'
  },
  component: () => import('@/pages/account/register/result')
}];

/**
 * 错误页面
 */

const errorPage = [{
  path: '/403',
  name: '403',
  meta: {
    title: '403'
  },
  component: () => import('@/pages/system/error/403')
}, {
  path: '/500',
  name: '500',
  meta: {
    title: '500'
  },
  component: () => import('@/pages/system/error/500')
}, {
  path: '/:pathMatch(.*)',
  name: '404',
  meta: {
    title: '404'
  },
  component: () => import('@/pages/system/error/404')
}];
// 导出需要显示菜单的
export const frameInRoutes = frameIn;

// 重新组织后导出
export default [...frameIn, ...frameOut, ...errorPage];