const pre = '/custom-group/';
export default {
  path: '/custom-group',
  title: '客户群运营',
  header: 'home',
  icon: 'ios-contacts',
  img: 'https://qiniu.hp.gimyingao.com/wecom/%E5%AE%A2%E6%88%B7%E7%BE%A42.png',
  imgS: 'https://qiniu.hp.gimyingao.com/wecom/%E5%AE%A2%E6%88%B7%E7%BE%A4.png',
  children: [{
    path: `${pre}group-list`,
    title: '客户群列表'
  }, {
    path: `${pre}group-label`,
    title: '客户群标签'
  }, {
    path: `${pre}crowd-send`,
    title: '客户群群发'
  }, {
    path: `${pre}group-sop`,
    title: '群SOP'
  }, {
    path: `${pre}group-sop/create`,
    title: '创建群SOP任务',
    hide: true
  }, {
    path: `${pre}group-sop/info`,
    title: '任务详情',
    hide: true
  }, {
    path: `${pre}crowd-send/info`,
    title: '群发详情',
    hide: true
  }, {
    path: `${pre}crowd-send/create`,
    title: '新建群发',
    hide: true
  }, {
    path: `${pre}group-statistics`,
    title: '客户群统计'
  }]
};