/* unplugin-vue-components disabled */import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href", "target"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("a", {
    href: _ctx.linkUrl,
    target: _ctx.target,
    class: _normalizeClass(["i-link", {
      'i-link-color': !$props.linkColor
    }]),
    onClick: [_cache[0] || (_cache[0] = _withModifiers($event => $options.handleClickItem($event, false), ["exact"])), _cache[1] || (_cache[1] = _withModifiers($event => $options.handleClickItem($event, true), ["ctrl"])), _cache[2] || (_cache[2] = _withModifiers($event => $options.handleClickItem($event, true), ["meta"]))]
  }, [_renderSlot(_ctx.$slots, "default")], 10 /* CLASS, PROPS */, _hoisted_1);
}