/* unplugin-vue-components disabled */import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "i-layout-tabs-main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TabPane = _resolveComponent("TabPane");
  const _component_Tabs = _resolveComponent("Tabs");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["i-layout-tabs", $options.classes]),
    style: _normalizeStyle($options.styles)
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_Tabs, {
    type: "card",
    "model-value": _ctx.current,
    animated: false,
    closable: "",
    draggable: _ctx.tabsOrder,
    onOnClick: $options.handleClickTab,
    onOnTabRemove: $options.handleClickClose,
    onOnDragDrop: $options.handleDragDrop
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.opened, (page, index) => {
      return _openBlock(), _createBlock(_component_TabPane, {
        key: index,
        label: h => $options.tabLabel(h, page),
        name: page.fullPath,
        closable: page.meta && page.meta.closable
      }, null, 8 /* PROPS */, ["label", "name", "closable"]);
    }), 128 /* KEYED_FRAGMENT */))]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value", "draggable", "onOnClick", "onOnTabRemove", "onOnDragDrop"]), _createCommentVNode(" <Dropdown class=\"i-layout-tabs-close\" @on-click=\"handleClose\">\n                <div class=\"i-layout-tabs-close-main\">\n                    <Icon type=\"ios-arrow-down\" />\n                </div>\n                <template #list>\n                    <DropdownMenu>\n                        <DropdownItem name=\"left\">\n                            <Icon type=\"md-arrow-back\" />\n                            {{ $t('basicLayout.tabs.left') }}\n                        </DropdownItem>\n                        <DropdownItem name=\"right\">\n                            <Icon type=\"md-arrow-forward\" />\n                            {{ $t('basicLayout.tabs.right') }}\n                        </DropdownItem>\n                        <DropdownItem name=\"other\">\n                            <Icon type=\"md-close\" />\n                            {{ $t('basicLayout.tabs.other') }}\n                        </DropdownItem>\n                        <DropdownItem name=\"all\">\n                            <Icon type=\"md-close-circle\" />\n                            {{ $t('basicLayout.tabs.all') }}\n                        </DropdownItem>\n                    </DropdownMenu>\n                </template>\n            </Dropdown> ")])], 6 /* CLASS, STYLE */);
}